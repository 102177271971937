import React from "react";
import { IonText } from "@ionic/react";
import styles from "./Breadcrumb.module.scss";
import { useHistory } from "react-router";

interface BreadcrumbProps {
  items: any;
  truncate?: boolean;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, truncate = false }) => {
  const history = useHistory();
  return (
    <nav className={`${styles.breadcrumb}`}>
      {items?.map((item: any, index: any) => (
        <div key={index} className={`${styles.breadcrumb__item}`}>
          <IonText
            className={`${styles.breadcrumb__link}`}
            onClick={() => {
              if (item?.url) {
                history.push(item?.url);
              }
            }}
          >
            {item.label}
          </IonText>
          <span className={`${styles.breadcrumb__separator}`}>/</span>
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumb;
