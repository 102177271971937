import { IonImg } from "@ionic/react";
import React from "react";
import styles from "./Footer.module.scss";
import { usePartner } from "../../Contexts/PartnerContext";

export const Footer = () => {
  const { partnerPid } = usePartner();
  return (
    <div className={styles.footer}>
      <span className="primary-title-color">
        {partnerPid === "ccbom"
          ? "Powered by Customer Capital in partnership with Scandid"
          : "Powered by"}
      </span>
      {partnerPid !== "ccbom" && (
        <IonImg src="assets/icon/scandidlogo.png"></IonImg>
      )}
    </div>
  );
};
