import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  DealHomePage,
  DealDetailPage,
  DealsListPage,
  CouponsHome,
  CouponDetail,
  ShoppingHome,
  ShoppingList,
  ShoppingDetail,
  CommissionDetail,
} from "./pages/index";
import { NoData, Search } from "./components/index";
import { animationBuilder } from "./PageAnimation";
import ReactGA from "react-ga4";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/default.css";
import "./theme/paynearby.css";
import "./theme/mos.css";
import "./theme/easidesi.css";
import "./theme/ast.css";
import "./global.scss";
import "./theme/spta.css";
import "./theme/pwa.css";
import "./theme/itt.css";
import "./theme/plx.css";
import "./theme/uae.css";
import "./theme/mahabank.css"
import "./theme/ethiopian.css";

import { ProfileIdProvider } from "./Contexts/UserContext";
import { usePartner } from "./Contexts/PartnerContext";
import { useEffect } from "react";
import { StoreList } from "./pages/store/store-list/StoreList";
import Login from "./pages/login/Login";
import { PublicRoute, ProtectedRoute } from "./RouteComponents/RouteConfig";
import { Cashback } from "./pages/cashback/cashback-component/Cashback";

setupIonicReact();

const App: React.FC = () => {
  ReactGA.initialize("G-13ENBQSHZZ");
  const { theme, partnerPid } = usePartner();
  useEffect(() => {
    if (partnerPid) {
      const faviconLink = document.createElement("link");
      faviconLink.rel = "shortcut icon";
      faviconLink.href = `/assets/icon/${theme}.png`;
      faviconLink.type = "image/png";
      faviconLink.id = "favicon";
      const existingFavicon = document.getElementById("favicon");
      if (existingFavicon) {
        document.head.removeChild(existingFavicon);
      }
      document.head.appendChild(faviconLink);
    }
  }, [partnerPid]);

  return (
    <IonReactRouter>
      <ProfileIdProvider>
        <IonApp className={`${theme}`}>
          <IonRouterOutlet animation={animationBuilder}>
            {/* Public route */}
            <PublicRoute path="/login" component={Login} exact={true} />
            {/* Redirect from root to shopping */}
            <PublicRoute path="/deals" component={DealHomePage} exact={true} />
            <PublicRoute
              path="/deal/:id"
              component={DealDetailPage}
              exact={true}
            />
            <PublicRoute
              path="/deals/:category/:subcategory"
              component={DealsListPage}
              exact={true}
            />
            <PublicRoute path="/coupons" component={CouponsHome} exact={true} />
            <PublicRoute
              path="/coupons/:id"
              component={CouponDetail}
              exact={true}
            />
            <PublicRoute path="/shopping" exact component={ShoppingHome} />
            <PublicRoute
              path="/shopping/pricelist/:category"
              exact
              component={ShoppingList}
            />
            <PublicRoute
              path="/shopping/pricelist/:category/:subcategory"
              exact
              component={ShoppingList}
            />
            <PublicRoute
              path="/shopping/product/:productid"
              exact
              component={ShoppingDetail}
            />
            <PublicRoute
              path="/:searchPage*/search"
              component={Search}
              exact={true}
            />
            <PublicRoute
              path="/commission/:store"
              component={CommissionDetail}
              exact={true}
            />
            <PublicRoute
              path="/store/list"
              component={StoreList}
              exact={true}
            />

            {/* Protected route */}
            <ProtectedRoute
              path="/cashback"
              component={Cashback}
              exact={true}
            />

            <Redirect from="/" to="/shopping" exact={true} />
            <Route render={() => <NoData props="404" />} />
          </IonRouterOutlet>
        </IonApp>
      </ProfileIdProvider>
    </IonReactRouter>
  );
};

export default App;
