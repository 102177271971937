import {
  IonItem,
  IonTitle,
  IonImg,
  IonCardTitle,
  IonCardHeader,
  IonText,
  IonCardContent,
  IonCard,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonButton,
} from "@ionic/react";
import { useHistory } from "react-router";
import { Cashback } from "../../../../components";
import {
  esdDealCardThumbnail,
  productThumbnail,
} from "../../../../thumbnailConfig";
import styles from "./DealCategory.module.scss";
import {
  googleAnalyticsViewItem,
  googleAnalyticsViewMore,
} from "../../../../utils/reactGa";
import { usePartner } from "../../../../Contexts/PartnerContext";
import { discountBadgePids } from "../../../../utils/pidHelper";

type Props = {
  props: any;
};
export const DealCategory = ({ props }: Props) => {
  const history = useHistory();
  const { partnerPid } = usePartner();
  const gaViewItemHandler = (item: any) => {
    googleAnalyticsViewItem(item?.logParams);
  };
  const gaViewMoreHandler = () => {
    googleAnalyticsViewMore(props);
  };
  const handleImageFailure = (e: any) => {
    e.target.src =
      partnerPid === "esd" ? esdDealCardThumbnail : productThumbnail;
  };
  const encodeURIComponentManual = (input: any) => {
    return input.replace(/%/g, "%25");
  };
  return (
    <div className={`${styles.dealCategory} ion-no-padding`}>
      <IonItem lines="none" className="ion-no-padding">
        <IonTitle
          className="ion-no-padding padl10 primary-title-color"
          style={{ fontSize: "15px" }}
        >
          {props?.display_name}
        </IonTitle>
        <IonButton
          className="font12 clear-btn"
          onClick={() => {
            gaViewMoreHandler();
            history.push(
              `/deals/${encodeURIComponent(
                encodeURIComponentManual(props.display_name)
              )}/${encodeURIComponent(
                encodeURIComponentManual(props.extra_value1.toLowerCase())
              )}`
            );
          }}
        >
          View More
        </IonButton>
      </IonItem>
      <div className="scrollx">
        {props?.deals.map((item: any) => {
          return (
            <div
              className="scroll-item"
              key={item.id}
              onClick={() => {
                gaViewItemHandler(item);
                history.push(`/deal/${item.id}`, item);
              }}
            >
              <IonCard>
                {discountBadgePids.includes(partnerPid) && item.origPrice > item.finalPrice && (
                  <div className={styles.discountBadge}>
                    <IonText color="light">
                      {" "}
                      {item.origPrice > item.finalPrice &&
                        `${Math.round(
                          ((item.origPrice - item.finalPrice) /
                            item.origPrice) *
                            100
                        )}% Off`}
                    </IonText>
                  </div>
                )}
                <div className={styles.productImg}>
                  <img
                    src={item?.img}
                    alt="product"
                    onError={(e) => handleImageFailure(e)}
                  />
                </div>
                <div
                  className={`${styles.contentContainer} pad10 card-grey-bg-color`}
                >
                  <IonCardHeader className="ion-no-padding">
                    <IonCardTitle className="font14 ion-text-capitalize font600 card-title-color">
                      {item.store}
                    </IonCardTitle>
                    <IonCardSubtitle className="ion-no-margin font10 line-2-clip card-subTitle-color">
                      {item.title}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent className="ion-no-padding">
                    <IonGrid className="ion-no-padding">
                      <IonRow className="font12">
                        <IonText
                          className={`${styles.productSectionProductPrice} font11 text-color-grey`}
                        >
                          {item.origPrice > item.finalPrice && (
                            <strong>{item?.origPriceStr}</strong>
                          )}
                        </IonText>
                      </IonRow>
                      <IonRow className="font12">
                        <IonText className="font12 card-title-color">
                          <strong>{item?.finalPriceStr}</strong>
                        </IonText>
                        &nbsp;&nbsp;
                        {!discountBadgePids.includes(partnerPid) && (
                          <IonText className="font12 card-title-color">
                            {item.origPrice > item.finalPrice &&
                              `(${Math.round(
                                ((item.origPrice - item.finalPrice) /
                                  item.origPrice) *
                                  100
                              )}% off)`}
                          </IonText>
                        )}
                      </IonRow>
                      <IonRow>
                        <Cashback
                          rewardText={item?.rewards_campaign}
                          styleName={"cashback"}
                        />
                      </IonRow>
                    </IonGrid>
                    <div></div>
                  </IonCardContent>
                </div>
              </IonCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};
