export const prodPartnerList = {
  easidesi: {
    domain: "easidesimall.travelx.ai",
    pid: "esd",
    key: "vhgvckshodppp",
    theme: "esd",
    mobLogoSrc: "/assets/icon/esd.png",
    webLogoSrc: "assets/webLogo/esd.png",
    showLogin: false,
  },
  paynearby: {
    domain: "paynearby.scandid.in",
    pid: "pnb",
    key: "vshgacvsjhcdv",
    theme: "pnb",
    mobLogoSrc: "/assets/icon/pnb.png",
    webLogoSrc: "assets/webLogo/pnb.png",
    showLogin: false,
  },
  mos: {
    domain: "shop.mshopmall.com",
    pid: "mos",
    key: "3qg95b1patlbe",
    theme: "mos",
    mobLogoSrc: "/assets/icon/MshopLogo.png",
    webLogoSrc: "assets/webLogo/mos.png",
    showLogin: false,
  },
  ast: {
    domain: "apnastore.biz-solutionz.com",
    pid: "ast",
    key: "khfdnjfbdnjfl",
    theme: "ast",
    mobLogoSrc: "/assets/icon/ApnaStoreLogo.png",
    webLogoSrc: "assets/webLogo/ast.png",
    showLogin: false,
  },
  earn10x: {
    domain: "earn10x.shopstacc.com",
    pid: "spta",
    key: "xvcdsgfsdhgss",
    theme: "spta",
    mobLogoSrc: "/assets/icon/shopstaccLogo.png",
    webLogoSrc: "assets/webLogo/spta.png",
    showLogin: false,
  },
  "max-easy-store": {
    domain: "maxeasystore.scandid.in",
    pid: "itt",
    key: "culoi343bnkjd",
    theme: "itt",
    mobLogoSrc: "/assets/icon/MaxEasyStore.png",
    webLogoSrc: "assets/webLogo/itt.png",
    showLogin: false,
  },
  uae: {
    domain: "ae.scandid.deals",
    pid: "uae",
    key: "akiw98539fj92",
    theme: "pwa",
    mobLogoSrc: "/assets/icon/scandidlogo.png",
    webLogoSrc: "assets/webLogo/pwa.png",
    showLogin: true,
  },
  us: {
    domain: "us.scandid.deals",
    pid: "usd",
    key: "plokijuyhgtfr",
    theme: "pwa",
    mobLogoSrc: "/assets/icon/scandidlogo.png",
    webLogoSrc: "assets/webLogo/pwa.png",
    showLogin: true,
  },
  ethiopian: {
    domain: "et-us.scandid.deals",
    pid: "etus",
    key: "o8dfm09ikg0pp",
    theme: "ethiopian",
    mobLogoSrc: "/assets/icon/ethiopian.png",
    webLogoSrc: "assets/webLogo/ethiopian.png",
    showLogin: true,
  },
};
