import {
  IonHeader,
  IonGrid,
  IonRow,
  IonButton,
  IonImg,
  IonSearchbar,
  IonButtons,
  IonIcon,
  IonPopover,
} from "@ionic/react";
import styles from "./Header.module.scss";
import { useHistory } from "react-router";
import { googleAnalyticsSearchTerm } from "../../utils/reactGa";
import { usePartner } from "../../Contexts/PartnerContext";
import { useState } from "react";
import { personCircle } from "ionicons/icons";
import { AccountPopoverContent } from "../AccountPopoverContent/AccountPopoverContent";
import { scandidPids } from "../../utils/pidHelper";

export const Header = ({ data, searchBar }: any) => {
  const history = useHistory();
  const { webLogoSrc, showLogin, partnerPid } = usePartner();
  const [popoverState, setShowPopover] = useState<any>({
    showPopover: false,
    event: undefined,
  });

  const closePopOver = () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
  };

  const isPidInArray = (pid: any) => {
    return scandidPids.includes(pid);
  };

  const handlePopoverClick = (e: React.MouseEvent) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };
  const activeTab = history.location.pathname.slice(1);
  let searchParam = new URLSearchParams(history.location.search).get("q");
  const filterParam = new URLSearchParams(history.location.search).get(
    "filter"
  );
  const sortParam = new URLSearchParams(history.location.search).get("sort");

  const searchHandler = (event: any) => {
    if (!history.location.pathname.includes("search")) {
      if (event.key === "Enter" && event.target.value !== "") {
        googleAnalyticsSearchTerm(
          event.target.value,
          history.location.pathname.slice(1)
        );
        history.push({
          pathname: `/${activeTab}/search/`,
          search: `q=${encodeURIComponent(event.target.value)}`,
        });
      }
    } else {
      if (event.key === "Enter" && event.target.value !== "") {
        if (searchParam && !filterParam && !sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(event.target.value)}`,
          });
        } else if (searchParam && filterParam && !sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&filter=${encodeURIComponent(filterParam)}`,
          });
        } else if (searchParam && filterParam && sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&filter=${encodeURIComponent(
              filterParam
            )}&sort=${encodeURIComponent(sortParam)}`,
          });
        } else if (searchParam && !filterParam && sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&sort=${encodeURIComponent(sortParam)}`,
          });
        }
      } else if (event.key === "Enter" && event.target.value === "") {
        history.goBack();
      }
    }
  };

  return (
    <div className={`${styles.header} ion-hide-sm-down`}>
      <IonHeader className="padt10 padb10">
        <IonGrid fixed className="ion-no-padding">
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonImg
              className={`${styles.logo}`}
              src={webLogoSrc}
              onClick={() => history.push("/shopping")}
            ></IonImg>
            {data &&
              data?.map((item: any, index: any) => {
                if (item.type === "searchBar") {
                  return (
                    <div
                      className={`${styles.searchBarContainer} ion-hide-md-down`}
                      key={index}
                    >
                      <IonSearchbar
                        className={`ion-no-padding`}
                        placeholder={item.display_name}
                        value={""}
                        onKeyPress={(event: any) => searchHandler(event)}
                      ></IonSearchbar>
                    </div>
                  );
                }
              })}
            {searchBar && (
              <div className={`${styles.searchBarContainer} ion-hide-md-down`}>
                <IonSearchbar
                  className={`ion-no-padding`}
                  placeholder="Search"
                  value={searchParam}
                  onKeyPress={(event: any) => searchHandler(event)}
                ></IonSearchbar>
              </div>
            )}
            <div className="d-flex">
              <div>
                <IonButton
                  className={
                    activeTab === "shopping"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/shopping`)}
                >
                  Shopping
                </IonButton>
                <IonButton
                  className={
                    activeTab === "deals"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/deals`)}
                >
                  Deals
                </IonButton>
                <IonButton
                  className={
                    activeTab === "coupons"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/coupons`)}
                >
                  Coupons
                </IonButton>
                {isPidInArray(partnerPid) && (
                  <IonButton
                    onClick={() => {
                      try {
                        window.open("https://blog.scandid.deals", "_blank");
                      } catch (error) {
                        console.error("Error opening link:", error);
                      }
                    }}
                  >
                    Blog
                  </IonButton>
                )}
              </div>
              {showLogin && (
                <IonButtons slot="end">
                  <IonButton onClick={handlePopoverClick}>
                    <IonIcon slot="icon-only" icon={personCircle} />
                  </IonButton>
                </IonButtons>
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className={`${styles.popOver}`}
      >
        <AccountPopoverContent closePopOver={closePopOver} />
      </IonPopover>
    </div>
  );
};
