import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonImg,
  IonRow,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";
import { Cashback } from "../../../../components";
import {
  esdDealListCardThumbnail,
  productThumbnail,
} from "../../../../thumbnailConfig";
import styles from "./DealListCard.module.scss";
import { googleAnalyticsViewItem } from "../../../../utils/reactGa";
import { usePartner } from "../../../../Contexts/PartnerContext";
import { discountBadgePids } from "../../../../utils/pidHelper";

type Props = {
  dealDetail: any;
};

export const DealListCard = ({ dealDetail }: Props) => {
  const history = useHistory();
  const { partnerPid } = usePartner();
  const gaHandler = () => {
    googleAnalyticsViewItem(dealDetail?.logParams);
  };
  const handleImageFailure = (e: any) => {
    e.target.src =
      partnerPid === "esd" ? esdDealListCardThumbnail : productThumbnail;
  };
  return (
    <div className={`${styles.dealListCardContainer} card-grey-bg-color`}>
      <IonCard
        className="ion-no-margin"
        onClick={() => {
          gaHandler();
          history.push(`/deal/${dealDetail.id}`);
        }}
      >
        {discountBadgePids.includes(partnerPid) && dealDetail.orig_price > dealDetail.final_price && (
          <div className={styles.discountBadge}>
            <IonText color="light">
              {" "}
              {dealDetail.orig_price > dealDetail.final_price &&
                `${Math.round(
                  ((dealDetail.orig_price - dealDetail.final_price) /
                    dealDetail.orig_price) *
                    100
                )}% Off`}
            </IonText>
          </div>
        )}
        <div className={styles.productImg}>
          <img
            src={dealDetail?.img}
            alt="dealImage"
            onError={(e) => handleImageFailure(e)}
          />
        </div>
        <div className={`${styles.dealListContentContainer} padt10`}>
          <IonCardHeader className="ion-no-padding padl10 marb10">
            <IonCardTitle className="line-2-clip card-title-color">
              {dealDetail.title}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="ion-no-padding padl10 padb10">
            <IonGrid className="ion-no-padding">
              <IonRow className="font12">
                <IonText
                  className={`${styles.dealPrice} font11 text-color-grey`}
                >
                  <strong>{dealDetail?.origPriceStr}</strong>
                </IonText>
              </IonRow>
              <IonRow className="font12">
                <IonText className="font12 card-title-color">
                  <strong>{dealDetail?.finalPriceStr}</strong>
                </IonText>
                &nbsp;&nbsp;
                {!discountBadgePids.includes(partnerPid) && (
                  <IonText className="font12 card-title-color">
                    (
                    {Math.round(
                      ((dealDetail.orig_price - dealDetail.final_price) /
                        dealDetail.orig_price) *
                        100
                    )}
                    % off)
                  </IonText>
                )}
              </IonRow>
              <IonRow className={`${styles.height18} mart6`}>
                <Cashback
                  rewardText={dealDetail?.rewards_campaign}
                  styleName={"cashback"}
                />
              </IonRow>
            </IonGrid>
            <div></div>
          </IonCardContent>
        </div>
      </IonCard>
    </div>
  );
};
